import { HmacSHA256 } from 'crypto-js'
import { getUnixTime } from 'date-fns';

function getToken() {
  // Get the current time in UTC and convert it to a Unix timestamp
  const now = new Date();
  const time = getUnixTime(now.getTime());
  return  'Syserauth ' + process.env.CLIENT_ID +'-'+ HmacSHA256(process.env.CLIENT_ID+time.toString(), process.env.CLIENT_SECRET).toString() + '-' + time.toString(16);
}

export default function ({ $axios, route, redirect, store }) {

  $axios.onRequest(config => {
    if (config.url.includes('apiv2')) {
      $axios.setHeader('credentials', process.env.API_CREDENTIALS)
      $axios.setHeader('checkdomain-header', process.env.FE_URL);
    } else {
      $axios.setHeader('credentials', getToken());
      $axios.setHeader('checkdomain-header', 'https://secretcircle.com');
    }
  })

  $axios.onError(error => {
    if (error.message === 'Both token and refresh token have expired. Your request was aborted.') {
      const redirectTo = `?message=expired`;
      redirect(redirectTo);
    }
  })

}