//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'notifications',
  props: ['notification'],
  async asyncData() {
    return {
      active: false,
    }
  },
  data() {
    return {
      active: false,
    }
  },
  mounted: function() {
    if (this.notification) {
      this.active = true;
    }
  },
  watch: {        
    notification: function () {
      this.active = true;
      this.$emit('notified', true);
      var self = this;
      var time = 5000
      if (this.notification && this.notification.timeout) {
        time = this.notification.timeout;
      }
      setTimeout(function() {
        self.active = false;
        self.$emit('notified' , false);
      }, time);
    }
  },
  methods: {
    href() {
      if (this.notification.href) {
       this.$router.push({
        path: this.notification.href,
      })
       var self = this;
       setTimeout(function() {
        self.active = false;
        self.$emit('notified' , false);
      }, 3000);
     }
   },
 }
}
