  export const state = () => ({
    filter: {
      active: false,
      sort: {
        title: 'Sorteren'
      },
      models: {
        title: 'Modellen'
      },
      tags: {
        title: 'Categorieën'
      }
    }
  })

  export const actions = {

  }
  export const getters = {

  }

  export const mutations = {
    setSort(state, sortQuery) { 
      let sort = {
        title: sortQuery.title,
      }
      sort[sortQuery.name] = sortQuery.direction;
      state.filter.sort = sort;
      state.filter.active = true;
    },
    setFilter(state, filterQuery) { 
      let filter = {
        title: filterQuery.title,
      }
      filter[filterQuery.type] = filterQuery.id;
      state.filter[filterQuery.type] = filter;
      state.filter.active = true;
    },
    reset(state, type) {
      const typeMap = {
        sort: { title: 'Sorteren' },
        models: { title: 'Modellen' },
        tags: { title: 'Categorieën' }
      };

      if (typeMap[type]) {
        state.filter[type] = typeMap[type];
      }
      if (state.filter.sort.title === 'Sorteren' && state.filter.models.title === 'Modellen' && state.filter.tags.title === 'Categorieën') {
        state.filter.active = false;
      }
    }

  }