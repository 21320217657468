//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			account: ({ accountStore: {account} }) => account,
			video: ({ resourcesStore: {video} }) => video,
			showSingle: ({ resourcesStore: {showSingle} }) => showSingle,
			episode: ({ resourcesStore: {episode} }) => episode,
			modal: ({ modalStore: {modal} }) => modal
		}),
	},
	data() {
		return {
			email: '',
			errors: {}
		}
	},
	methods: {
		sendEmailCheck() {
			this.$store.commit('accountStore/setEmail', this.email);
			this.$store.dispatch('accountStore/checkEmail', { email:this.email, router: this.$router });
		},
		close: function() {
			if (event.srcElement.className == 'modal-container' || event.srcElement.className == 'close_container' || event.srcElement.className == 'close' || event.srcElement.tagName == 'path') {
				this.$store.commit('modalStore/setModal', false)
			}
		},
	}
}
