//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import HomeIcon from '@/assets/svg/icon_home.svg';
import FilmsIcon from '@/assets/svg/icon_seksfilms-1.svg';
import ModellenIcon from '@/assets/svg/icon_modellen.svg';
import ShowsIcon from '@/assets/svg/icon_tvshows.svg';
import CamsIcon from '@/assets/svg/icon_webcams.svg';
export default {
    components: {
        HomeIcon,
        FilmsIcon,
        ModellenIcon,
        ShowsIcon,
        CamsIcon
    },
    computed: {
        ...mapState({
          playerState: ({ mxmStore: {playerState} }) => playerState,
          stream: ({ mxmStore: {stream} }) => stream,
      }),
    },
    data  () {
        return{
            nav: {
                home: false,
                about: false,
                projects: false,
                method: false,
                contact: false,
            },
            dropdown: {
                about: false,
                lessons: false,
                fitness: false
            },
        }
    },
    methods:{
        toggleDropdown(item){
            this.dropdown[item] = !this.dropdown[item];
        }
    },
    watch: {
        '$route.path': function() {
            if (this.dropdown.about) {
                this.dropdown.about = false;
            }
            if (this.dropdown.lessons) {
                this.dropdown.lessons = false;
            }
            if (this.dropdown.fitness) {
                this.dropdown.fitness = false;
            }
        },
        $route: function() {
            if (this.$route.meta.navActive) {
                this.nav = {
                    home: false,
                    about: false,
                    projects: false,
                    method: false,
                    contact: false
                };
                this.nav[this.$route.meta.navActive].active = !this.nav[this.$route.meta.navActive].active       
            }
        }
    },
}
