// Use to check if a modal popup is active

export const strict = false;

export const state = () => ({
  modal: false
})

export const getters = {
  modal(state) {
    return state.modal;
  }
}

export const mutations = {
  setModal(state, modal) {
    state.modal = modal;
  }
}