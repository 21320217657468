//
//
//
//
//
//
//
//
//
//
//

export default {
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e);
    }
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
    }
  },
  props: ['paywall'],
  methods: {
    async sub() {
      try {
        const token = await this.$recaptcha.execute('login')
        this.login.g_recaptcha_response = token;
        let response = await this.$auth.loginWith('laravelJWT', { data: this.login })
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.message && err.response.data.message == 'reset_password') {
          var notificationObj = [];
          notificationObj.type = 'info';
          notificationObj.message = 'Om in te loggen op onze nieuwe website moet je jouw wachtwoord opnieuw instellen. Klik hiervoor op wachtwoord vergeten.';
          this.$store.commit('notificationStore/setNotification', notificationObj);
        } else {
          var notificationObj = [];
          notificationObj.type = 'error';
          notificationObj.message = 'We kunnen je niet inloggen met deze gegevens.';
          this.$store.commit('notificationStore/setNotification', notificationObj);
          this.$sentry.captureException(err);
        }
      }
    },
    sendToggleTab: function(event) {
     this.$emit('toggle')
   }
 },
 beforeDestroy() {
  this.$recaptcha.destroy()
}
}
