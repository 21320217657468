import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0cee6ca2 = () => interopDefault(import('../pages/modellen/index.vue' /* webpackChunkName: "pages/modellen/index" */))
const _a05c09f0 = () => interopDefault(import('../pages/seksfilms/index.vue' /* webpackChunkName: "pages/seksfilms/index" */))
const _3c366a66 = () => interopDefault(import('../pages/transactie.vue' /* webpackChunkName: "pages/transactie" */))
const _080f312f = () => interopDefault(import('../pages/tv-gids/index.vue' /* webpackChunkName: "pages/tv-gids/index" */))
const _8e498aa4 = () => interopDefault(import('../pages/tv-shows/index.vue' /* webpackChunkName: "pages/tv-shows/index" */))
const _390cf6aa = () => interopDefault(import('../pages/webcams/index.vue' /* webpackChunkName: "pages/webcams/index" */))
const _515c35f7 = () => interopDefault(import('../pages/zoekresultaten/index.vue' /* webpackChunkName: "pages/zoekresultaten/index" */))
const _9ffd26b2 = () => interopDefault(import('../pages/account/gegevens-voorkeuren.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren" */))
const _5167a08a = () => interopDefault(import('../pages/account/gehuurd.vue' /* webpackChunkName: "pages/account/gehuurd" */))
const _e12b9a1a = () => interopDefault(import('../pages/account/inloggen.vue' /* webpackChunkName: "pages/account/inloggen" */))
const _039778af = () => interopDefault(import('../pages/account/mijn-credits.vue' /* webpackChunkName: "pages/account/mijn-credits" */))
const _db933904 = () => interopDefault(import('../pages/account/mijn-favorieten.vue' /* webpackChunkName: "pages/account/mijn-favorieten" */))
const _29435180 = () => interopDefault(import('../pages/account/registreren.vue' /* webpackChunkName: "pages/account/registreren" */))
const _289ee24f = () => interopDefault(import('../pages/account/unlimited.vue' /* webpackChunkName: "pages/account/unlimited" */))
const _753210ef = () => interopDefault(import('../pages/account/wachtwoord-herstellen.vue' /* webpackChunkName: "pages/account/wachtwoord-herstellen" */))
const _001c25d3 = () => interopDefault(import('../pages/account/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/account/wachtwoord-vergeten" */))
const _5f85846c = () => interopDefault(import('../pages/betalen/huurtegoed.vue' /* webpackChunkName: "pages/betalen/huurtegoed" */))
const _0d938c47 = () => interopDefault(import('../pages/betalen/unlimited.vue' /* webpackChunkName: "pages/betalen/unlimited" */))
const _ddc8076e = () => interopDefault(import('../pages/info/aanmelden/index.vue' /* webpackChunkName: "pages/info/aanmelden/index" */))
const _100f90b2 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _3898bf62 = () => interopDefault(import('../pages/info/contact/index.vue' /* webpackChunkName: "pages/info/contact/index" */))
const _5cd21919 = () => interopDefault(import('../pages/info/feedback/index.vue' /* webpackChunkName: "pages/info/feedback/index" */))
const _3cdd7cd4 = () => interopDefault(import('../pages/info/jouw-provider.vue' /* webpackChunkName: "pages/info/jouw-provider" */))
const _0e2b1016 = () => interopDefault(import('../pages/info/kijk-gratis.vue' /* webpackChunkName: "pages/info/kijk-gratis" */))
const _0231a115 = () => interopDefault(import('../pages/info/nieuwsbrief/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/index" */))
const _2ab71d36 = () => interopDefault(import('../pages/info/over.vue' /* webpackChunkName: "pages/info/over" */))
const _975fd0f2 = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _49c0bc90 = () => interopDefault(import('../pages/info/uitschrijven/index.vue' /* webpackChunkName: "pages/info/uitschrijven/index" */))
const _6dde9e9a = () => interopDefault(import('../pages/info/veelgestelde-vragen.vue' /* webpackChunkName: "pages/info/veelgestelde-vragen" */))
const _02d4dcd9 = () => interopDefault(import('../pages/info/aanmelden/aanmeldformulier/index.vue' /* webpackChunkName: "pages/info/aanmelden/aanmeldformulier/index" */))
const _f82df732 = () => interopDefault(import('../pages/info/contact/bedankt.vue' /* webpackChunkName: "pages/info/contact/bedankt" */))
const _7f32cc5e = () => interopDefault(import('../pages/info/feedback/bedankt.vue' /* webpackChunkName: "pages/info/feedback/bedankt" */))
const _4ad0455a = () => interopDefault(import('../pages/info/nieuwsbrief/bedankt.vue' /* webpackChunkName: "pages/info/nieuwsbrief/bedankt" */))
const _11766cb4 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/index" */))
const _2b0fb198 = () => interopDefault(import('../pages/info/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/uitschrijven/uitgeschreven" */))
const _a0d8bfc4 = () => interopDefault(import('../pages/info/aanmelden/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/info/aanmelden/aanmeldformulier/bedankt" */))
const _c34c7ed8 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/uitgeschreven" */))
const _64b96a22 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _105baf32 = () => interopDefault(import('../pages/modellen/_slug.vue' /* webpackChunkName: "pages/modellen/_slug" */))
const _a3c94c80 = () => interopDefault(import('../pages/seksfilms/_slug.vue' /* webpackChunkName: "pages/seksfilms/_slug" */))
const _19a9a58a = () => interopDefault(import('../pages/tv-shows/_show/index.vue' /* webpackChunkName: "pages/tv-shows/_show/index" */))
const _506c10e3 = () => interopDefault(import('../pages/tv-shows/_show/_episode.vue' /* webpackChunkName: "pages/tv-shows/_show/_episode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/modellen",
    component: _0cee6ca2,
    name: "modellen"
  }, {
    path: "/seksfilms",
    component: _a05c09f0,
    name: "seksfilms"
  }, {
    path: "/transactie",
    component: _3c366a66,
    name: "transactie"
  }, {
    path: "/tv-gids",
    component: _080f312f,
    name: "tv-gids"
  }, {
    path: "/tv-shows",
    component: _8e498aa4,
    name: "tv-shows"
  }, {
    path: "/webcams",
    component: _390cf6aa,
    name: "webcams"
  }, {
    path: "/zoekresultaten",
    component: _515c35f7,
    name: "zoekresultaten"
  }, {
    path: "/account/gegevens-voorkeuren",
    component: _9ffd26b2,
    name: "account-gegevens-voorkeuren"
  }, {
    path: "/account/gehuurd",
    component: _5167a08a,
    name: "account-gehuurd"
  }, {
    path: "/account/inloggen",
    component: _e12b9a1a,
    name: "account-inloggen"
  }, {
    path: "/account/mijn-credits",
    component: _039778af,
    name: "account-mijn-credits"
  }, {
    path: "/account/mijn-favorieten",
    component: _db933904,
    name: "account-mijn-favorieten"
  }, {
    path: "/account/registreren",
    component: _29435180,
    name: "account-registreren"
  }, {
    path: "/account/unlimited",
    component: _289ee24f,
    name: "account-unlimited"
  }, {
    path: "/account/wachtwoord-herstellen",
    component: _753210ef,
    name: "account-wachtwoord-herstellen"
  }, {
    path: "/account/wachtwoord-vergeten",
    component: _001c25d3,
    name: "account-wachtwoord-vergeten"
  }, {
    path: "/betalen/huurtegoed",
    component: _5f85846c,
    name: "betalen-huurtegoed"
  }, {
    path: "/betalen/unlimited",
    component: _0d938c47,
    name: "betalen-unlimited"
  }, {
    path: "/info/aanmelden",
    component: _ddc8076e,
    name: "info-aanmelden"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _100f90b2,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/contact",
    component: _3898bf62,
    name: "info-contact"
  }, {
    path: "/info/feedback",
    component: _5cd21919,
    name: "info-feedback"
  }, {
    path: "/info/jouw-provider",
    component: _3cdd7cd4,
    name: "info-jouw-provider"
  }, {
    path: "/info/kijk-gratis",
    component: _0e2b1016,
    name: "info-kijk-gratis"
  }, {
    path: "/info/nieuwsbrief",
    component: _0231a115,
    name: "info-nieuwsbrief"
  }, {
    path: "/info/over",
    component: _2ab71d36,
    name: "info-over"
  }, {
    path: "/info/privacy",
    component: _975fd0f2,
    name: "info-privacy"
  }, {
    path: "/info/uitschrijven",
    component: _49c0bc90,
    name: "info-uitschrijven"
  }, {
    path: "/info/veelgestelde-vragen",
    component: _6dde9e9a,
    name: "info-veelgestelde-vragen"
  }, {
    path: "/info/aanmelden/aanmeldformulier",
    component: _02d4dcd9,
    name: "info-aanmelden-aanmeldformulier"
  }, {
    path: "/info/contact/bedankt",
    component: _f82df732,
    name: "info-contact-bedankt"
  }, {
    path: "/info/feedback/bedankt",
    component: _7f32cc5e,
    name: "info-feedback-bedankt"
  }, {
    path: "/info/nieuwsbrief/bedankt",
    component: _4ad0455a,
    name: "info-nieuwsbrief-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven",
    component: _11766cb4,
    name: "info-nieuwsbrief-uitschrijven"
  }, {
    path: "/info/uitschrijven/uitgeschreven",
    component: _2b0fb198,
    name: "info-uitschrijven-uitgeschreven"
  }, {
    path: "/info/aanmelden/aanmeldformulier/bedankt",
    component: _a0d8bfc4,
    name: "info-aanmelden-aanmeldformulier-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven/uitgeschreven",
    component: _c34c7ed8,
    name: "info-nieuwsbrief-uitschrijven-uitgeschreven"
  }, {
    path: "/",
    component: _64b96a22,
    name: "index"
  }, {
    path: "/modellen/:slug",
    component: _105baf32,
    name: "modellen-slug"
  }, {
    path: "/seksfilms/:slug",
    component: _a3c94c80,
    name: "seksfilms-slug"
  }, {
    path: "/tv-shows/:show",
    component: _19a9a58a,
    name: "tv-shows-show"
  }, {
    path: "/tv-shows/:show?/:episode",
    component: _506c10e3,
    name: "tv-shows-show-episode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
