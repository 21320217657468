export const strict = false;

export const state = () => ({
  formStatus: {
    sending: false,
    complete: false,
    errors: {},
  }
})

export const actions = {
  sendForm(context, formdata) {
    var formStatus = [];
    formStatus.type = 'sending';
    formStatus.todo = true;
    context.commit('updateFormStatus', formStatus);
    var notificationObj = [];
    
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/mail', formdata)
    .then(response => {
      formStatus.type = 'sending';
      formStatus.todo = false;
      context.commit('updateFormStatus', formStatus);

      formStatus.type = 'complete';
      formStatus.todo = true;
      context.commit('updateFormStatus', formStatus);
      var goto = window.location.href + '/bedankt';
      for (var pair of formdata.entries()) {
        if(pair[0] === 'gender' && pair[1] === 'stel'){
          goto = window.location.href + '/bedankt?gender=stel';
        }
      }
      window.location = goto;
    }).catch((error) => {
      console.log(error);
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan. Probeer het opnieuw of stuur een mailtje naar info@secretcircle.com';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });  

      formStatus.type = 'sending';
      formStatus.todo = false;
      context.commit('updateFormStatus', formStatus);

      formStatus.type = 'send';
      formStatus.text = true;
      context.commit('updateFormStatus', formStatus);

      this.app.$sentry.captureException(error);
    });
  },
  newsletter(context, formdata) {
    var formStatus = [];
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/segment/add-email/' + formdata.segment, formdata)
    .then(response => {
      formStatus.type = 'sending';
      formStatus.todo = false;
      context.commit('updateFormStatus', formStatus);

      formStatus.type = 'complete';
      formStatus.todo = true;
      context.commit('updateFormStatus', formStatus);
      if (formdata.subscribe === 1) {
        var goto = window.location.href + '/bedankt';
      } else {
        var goto = window.location.href + '/uitgeschreven';
      }
      window.location = goto;

    }).catch((error) => {
      console.log(error);
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan. Probeer het opnieuw of stuur een mailtje naar info@secretcircle.com';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });  

      formStatus.type = 'sending';
      formStatus.todo = false;
      context.commit('updateFormStatus', formStatus);

      formStatus.type = 'send';
      formStatus.text = true;
      context.commit('updateFormStatus', formStatus);
      
      this.app.$sentry.captureException(error);
    });
  }
}

export const mutations = {
  updateFormStatus(state, data) {
    state.formStatus[data.type] = data.todo;
  },
  updateErrors(state, data) {
    state.formStatus.errors[data.type] = data.text;
  },
  resetErrors(state) {
    state.formStatus.errors = {};
  }
}