//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapState } from 'vuex'

  import cookie from '~/components/cookie'
  import Header from '~/components/header/Header'
  import Footer from '~/components/Footer'
  import Navigation from '~/components/Navigation'
  import notificationHandler from '~/components/notification'
  import account from '~/components/user/account_modal'

  export default {
    components: {
      cookie,
      Header,
      Footer,
      Navigation,
      account,
      notificationHandler,
    },
    computed: {
      ...mapState({
        modal: ({ modalStore: {modal} }) => modal,
        notification: ({ notificationStore: {notification} }) => notification,
        playerState: ({ mxmStore: {playerState} }) => playerState,
        stream: ({ mxmStore: {stream} }) => stream,
      }),
      mobile: function () {
        return this.$root.isMobile;
      },
    },
    data() {      
      return {
        lastPos: 0,
        notified: false,
        phablet: false
      };
    },
    watch: {
     $route: function() {
      this.switchScroll('on');
    },
    mobile: function () {
      if (this.mobile) {
        this.phablet = true;
      }
    },
    modal: {
      handler() {
        if (this.modal) {
          this.switchScroll('off');
        } else {
          this.switchScroll('on');
        }
      },
      deep: true
    },
    playerState: {
      handler() {
        if (this.playerState.type != 'preroll' && this.playerState.type != 'trailer' && this.playerState.status == 'playing' && !this.$root.isMobile && !this.$root.smallDevice || this.playerState.type != 'preroll' && this.playerState.type != 'trailer' && this.playerState.status == 'pause' && !this.$root.isMobile && !this.$root.smallDevice) {
          this.switchScroll('off');
        } else {
          this.switchScroll('on');
        }
      },
      deep: true
    }
  },
  methods: {
    notifieer(event) {
      this.notified = event;
    },
    checkMobile() {
      if (this.$root.isMobile) {
        this.phablet = true;
      }
    },
    handleScroll(event) {
      var position = window.scrollY;
      if (position < 0) {
        position = 0;
      }
      if (this.lastPos <= 0) {
        this.lastPos = position;
      }

      if (position > (this.lastPos + 100)) {
        this.lastPos = position;
        this.$root.scroll = true;
      } else if (position < (this.lastPos - 100)) {
        this.lastPos = position;
        this.$root.scroll = false;
      }
      if (position > 20) {
        this.$root.notontop = true;
      } else {
        this.$root.notontop = false;
      }

    },
  },
  mounted: function() {
    window.addEventListener('scroll', this.handleScroll);
    this.checkMobile();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
