//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { slugToTitle } from '@/helpers/filters.js';

export default {
    props: ['slug', 'name', 'type'],
    computed: {
        ...mapState({
          playerState: ({ mxmStore: {playerState} }) => playerState,
          stream: ({ mxmStore: {stream} }) => stream,

      }),
    },
    methods: {
        slugToTitle,
    },
}
