import { serializeCam } from 'assets/js/serialize.js';

export const strict = false;

const DEFAULT_QUERY_FILTERS = {
  // geslacht: 'v',
  geslacht: '',
  leeftijd: '',
  uiterlijk: '',
  haar: '',
  figuur: '',
  taal: 'nl',
  orderby: 'waarderingdesc',
  page: 1,
  limit: 15,
}

const DEFAULT_FILTERS_ACTIVATED = {
  // geslacht: 'Vrouwen',
  geslacht: '',
  taal: 'Nederlands',
  leeftijd: false,
  uiterlijk: false,
  haar: false,
  figuur: false,
  orderby: false,
}

export const state = () => ({
  cams: [],
  loading: false,

  camsQuery: {
    geslacht: 'v',
    leeftijd: '',
    uiterlijk: '',
    haar: '',
    figuur: '',
    taal: 'NL',
    orderby: {
      waardering: 'desc'
    },
    online: '1',
    limit: 20,
    page: 1,
  },

  camFilter: {
    active: true,
    orderby: 'Sorteren',
    allFilters: 'Filters: AAN',
    search: 'Zoeken',
    activated: { ...DEFAULT_FILTERS_ACTIVATED }
  }
})

export const actions = {
  async getCams({commit}, query) {
    if(query.page === 1) {
      commit('setLoading', 'loading'); 
    } else {
      commit('setLoading', 'loadmore'); 
    }
    await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/webcams?query=' + formatFilters(query))
    .then(response => {
      if (query.page === 1) {
        commit('setAllCams', response.data);   
      } else {
        commit('mapAllCams', response.data);   
      }   
    }).catch((error) => {
      console.log(error);
      commit('setLoading', 'error'); 
      this.app.$sentry.captureException(error);
    });
  }, 
}

export const getters = {
  cams(state) {
    return state.cams;
  },
  camFilter(state) {
    return state.camFilter;
  }
}

export const mutations = {
  setAllCams(state, data) {
    if (data.data) {
      var cam = data.data;
      cam.forEach((item, i) => {
        item.id = i + 1;
      });
      state.cams = cam;
    } else {
      state.cams = false;
    }
    state.loading = 'done'; 
  },
  mapAllCams(state, data) {
    state.cams = state.cams.concat(data.data);
    state.loading = 'done'; 
  },
  setLoading(state, todo) {
    state.loading = todo;
  },
  updateQuery(state, query) {
    state.camsQuery = query;    
  },
  search(state, query) {
    if (query.type && query.input) {
      state.camsQuery.modelnaam = query.input;
    } else {
      state.camsQuery.modelnaam = false;
    }
  },
  updateFilterState(state, filterState) {
    state.camFilter.activated[filterState.type] = filterState.name;
    state.camFilter.active = true;
    if (filterState.type != 'orderby' && filterState.type != 'search') {
      state.camFilter.allFilters = 'Filters: AAN'
    }
    if(filterState.type == 'orderby') {
      state.camFilter.orderby = filterState.name
    }
    if (state.camFilter.orderby == 'Sorteren' && state.camFilter.models == 'Modellen' && state.camFilter.categories == 'Categorieën') {
      state.camFilter.active = false;
    }
  },
  resetFilters(state) {
    state.camFilter.activated = { ...DEFAULT_FILTERS_ACTIVATED };
    Object.assign(state.camsQuery, { ...DEFAULT_QUERY_FILTERS });
  }
}

function formatFilters(filters) {
  return Object.entries(filters)
  .map(([key, value]) => {
    if (key === "orderby") {
      var orderby = Object.entries(value).map(([sort, dir]) => {
        return 'orderby[' + sort + ']=' + dir
      })
      return orderby;
    }
    if (key === "leeftijd" && value) {
      return `where[leeftijd][between]=${value}`
    }
    if (key === "leeftijd" && value === "") {
      return `where[online][in]=`
    }
    if (key === "modelnaam" && value) {
      return `where[modelnaam][like]=${value}`
    }
    if (key === "limit" || key === "page") {
      return `${key}=${value}`
    }
    return `where[${key}]=${value}`
  })
  .join('&');
}