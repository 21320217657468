import Vue from 'vue'
Vue.mixin({
    data() {
        return {
            prevRoute: [],
            navigation: {

            }
        }
    },
    watch: {
        $route(to, from, next) {
            // this.navigation[from.name] = false;
            // this.navigation[this.$route.name] = !this.navigation[this.$route.name];
            if (from) {
                this.prevRoute = from;
            }else{
                this.prevRoute = []; 
            }
        },
    },
    created: function() {
        if(this.$route) {
            // this.navigation[this.$route.name] = !this.navigation[this.$route.name];
        }
    }
}) 