export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Cookie = () => import('../../components/cookie.vue' /* webpackChunkName: "components/cookie" */).then(c => wrapFunctional(c.default || c))
export const Loadmore = () => import('../../components/loadmore.vue' /* webpackChunkName: "components/loadmore" */).then(c => wrapFunctional(c.default || c))
export const MaxxXsPlayer = () => import('../../components/maxxXsPlayer.vue' /* webpackChunkName: "components/maxx-xs-player" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const RatingStars = () => import('../../components/ratingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c))
export const RatingStarsNew = () => import('../../components/ratingStarsNew.vue' /* webpackChunkName: "components/rating-stars-new" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/sectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const FormsContact = () => import('../../components/forms/contact.vue' /* webpackChunkName: "components/forms-contact" */).then(c => wrapFunctional(c.default || c))
export const FormsFeedback = () => import('../../components/forms/feedback.vue' /* webpackChunkName: "components/forms-feedback" */).then(c => wrapFunctional(c.default || c))
export const FormsMan = () => import('../../components/forms/man.vue' /* webpackChunkName: "components/forms-man" */).then(c => wrapFunctional(c.default || c))
export const FormsNieuwsbrief = () => import('../../components/forms/nieuwsbrief.vue' /* webpackChunkName: "components/forms-nieuwsbrief" */).then(c => wrapFunctional(c.default || c))
export const FormsStel = () => import('../../components/forms/stel.vue' /* webpackChunkName: "components/forms-stel" */).then(c => wrapFunctional(c.default || c))
export const FormsTrans = () => import('../../components/forms/trans.vue' /* webpackChunkName: "components/forms-trans" */).then(c => wrapFunctional(c.default || c))
export const FormsUitschrijven = () => import('../../components/forms/uitschrijven.vue' /* webpackChunkName: "components/forms-uitschrijven" */).then(c => wrapFunctional(c.default || c))
export const FormsUitschrijvenAll = () => import('../../components/forms/uitschrijvenAll.vue' /* webpackChunkName: "components/forms-uitschrijven-all" */).then(c => wrapFunctional(c.default || c))
export const FormsVrouw = () => import('../../components/forms/vrouw.vue' /* webpackChunkName: "components/forms-vrouw" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderInfo = () => import('../../components/header/info.vue' /* webpackChunkName: "components/header-info" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchbar = () => import('../../components/header/searchbar.vue' /* webpackChunkName: "components/header-searchbar" */).then(c => wrapFunctional(c.default || c))
export const HeaderUserLogin = () => import('../../components/header/userLogin.vue' /* webpackChunkName: "components/header-user-login" */).then(c => wrapFunctional(c.default || c))
export const HomeVideoForYou = () => import('../../components/home/VideoForYou.vue' /* webpackChunkName: "components/home-video-for-you" */).then(c => wrapFunctional(c.default || c))
export const HomeVideoOfTheWeek = () => import('../../components/home/VideoOfTheWeek.vue' /* webpackChunkName: "components/home-video-of-the-week" */).then(c => wrapFunctional(c.default || c))
export const HomeEpgNow = () => import('../../components/home/epg_now.vue' /* webpackChunkName: "components/home-epg-now" */).then(c => wrapFunctional(c.default || c))
export const HomeFilmCollections = () => import('../../components/home/film_collections.vue' /* webpackChunkName: "components/home-film-collections" */).then(c => wrapFunctional(c.default || c))
export const HomeLiveCams = () => import('../../components/home/live_cams.vue' /* webpackChunkName: "components/home-live-cams" */).then(c => wrapFunctional(c.default || c))
export const HomeNewShows = () => import('../../components/home/new_shows.vue' /* webpackChunkName: "components/home-new-shows" */).then(c => wrapFunctional(c.default || c))
export const HomePopulairModels = () => import('../../components/home/populair_models.vue' /* webpackChunkName: "components/home-populair-models" */).then(c => wrapFunctional(c.default || c))
export const HomePopunder = () => import('../../components/home/popunder.vue' /* webpackChunkName: "components/home-popunder" */).then(c => wrapFunctional(c.default || c))
export const HomeRecommendedVideos = () => import('../../components/home/recommended_videos.vue' /* webpackChunkName: "components/home-recommended-videos" */).then(c => wrapFunctional(c.default || c))
export const HomeTopVideos = () => import('../../components/home/top_videos.vue' /* webpackChunkName: "components/home-top-videos" */).then(c => wrapFunctional(c.default || c))
export const PaymentMobilePincode = () => import('../../components/payment/mobile_pincode.vue' /* webpackChunkName: "components/payment-mobile-pincode" */).then(c => wrapFunctional(c.default || c))
export const PaymentModule = () => import('../../components/payment/module.vue' /* webpackChunkName: "components/payment-module" */).then(c => wrapFunctional(c.default || c))
export const PaymentPincode = () => import('../../components/payment/pincode.vue' /* webpackChunkName: "components/payment-pincode" */).then(c => wrapFunctional(c.default || c))
export const PaymentPpm = () => import('../../components/payment/ppm.vue' /* webpackChunkName: "components/payment-ppm" */).then(c => wrapFunctional(c.default || c))
export const PopunderNewWebsite = () => import('../../components/popunder/new_website.vue' /* webpackChunkName: "components/popunder-new-website" */).then(c => wrapFunctional(c.default || c))
export const SexfilmsFreeVideo = () => import('../../components/sexfilms/freeVideo.vue' /* webpackChunkName: "components/sexfilms-free-video" */).then(c => wrapFunctional(c.default || c))
export const SexfilmsPlannedVideo = () => import('../../components/sexfilms/plannedVideo.vue' /* webpackChunkName: "components/sexfilms-planned-video" */).then(c => wrapFunctional(c.default || c))
export const SexfilmsRandomCategoryVideos = () => import('../../components/sexfilms/randomCategoryVideos.vue' /* webpackChunkName: "components/sexfilms-random-category-videos" */).then(c => wrapFunctional(c.default || c))
export const SexfilmsRandomModelVideos = () => import('../../components/sexfilms/randomModelVideos.vue' /* webpackChunkName: "components/sexfilms-random-model-videos" */).then(c => wrapFunctional(c.default || c))
export const SexfilmsRecentVideos = () => import('../../components/sexfilms/recentVideos.vue' /* webpackChunkName: "components/sexfilms-recent-videos" */).then(c => wrapFunctional(c.default || c))
export const UserAccountModal = () => import('../../components/user/account_modal.vue' /* webpackChunkName: "components/user-account-modal" */).then(c => wrapFunctional(c.default || c))
export const UserForgot = () => import('../../components/user/forgot.vue' /* webpackChunkName: "components/user-forgot" */).then(c => wrapFunctional(c.default || c))
export const UserLogin = () => import('../../components/user/login.vue' /* webpackChunkName: "components/user-login" */).then(c => wrapFunctional(c.default || c))
export const UserRegister = () => import('../../components/user/register.vue' /* webpackChunkName: "components/user-register" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountNav = () => import('../../components/content/account/accountNav.vue' /* webpackChunkName: "components/content-account-nav" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountCredits = () => import('../../components/content/account/credits.vue' /* webpackChunkName: "components/content-account-credits" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountOnbeperkt = () => import('../../components/content/account/onbeperkt.vue' /* webpackChunkName: "components/content-account-onbeperkt" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountOpzeggen = () => import('../../components/content/account/opzeggen.vue' /* webpackChunkName: "components/content-account-opzeggen" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountProfile = () => import('../../components/content/account/profile.vue' /* webpackChunkName: "components/content-account-profile" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountRented = () => import('../../components/content/account/rented.vue' /* webpackChunkName: "components/content-account-rented" */).then(c => wrapFunctional(c.default || c))
export const ContentCam = () => import('../../components/content/cam/cam.vue' /* webpackChunkName: "components/content-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamNewCard = () => import('../../components/content/cam/cam_new_card.vue' /* webpackChunkName: "components/content-cam-new-card" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersCam = () => import('../../components/content/cam/filters_cam.vue' /* webpackChunkName: "components/content-cam-filters-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamHighlightedCams = () => import('../../components/content/cam/highlightedCams.vue' /* webpackChunkName: "components/content-cam-highlighted-cams" */).then(c => wrapFunctional(c.default || c))
export const ContentCamRegisterButton = () => import('../../components/content/cam/registerButton.vue' /* webpackChunkName: "components/content-cam-register-button" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyCamDummy = () => import('../../components/content/dummy/cam_dummy.vue' /* webpackChunkName: "components/content-dummy-cam-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyDetailedDummy = () => import('../../components/content/dummy/detailed_dummy.vue' /* webpackChunkName: "components/content-dummy-detailed-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyEpgDummy = () => import('../../components/content/dummy/epg_dummy.vue' /* webpackChunkName: "components/content-dummy-epg-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyGalleryDummy = () => import('../../components/content/dummy/gallery_dummy.vue' /* webpackChunkName: "components/content-dummy-gallery-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyModelResourceDummy = () => import('../../components/content/dummy/modelResource_dummy.vue' /* webpackChunkName: "components/content-dummy-model-resource-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyModelDummy = () => import('../../components/content/dummy/model_dummy.vue' /* webpackChunkName: "components/content-dummy-model-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummySearchDummy = () => import('../../components/content/dummy/search_dummy.vue' /* webpackChunkName: "components/content-dummy-search-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyShowResourceDummy = () => import('../../components/content/dummy/showResource_dummy.vue' /* webpackChunkName: "components/content-dummy-show-resource-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyShowDummy = () => import('../../components/content/dummy/show_dummy.vue' /* webpackChunkName: "components/content-dummy-show-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentDummyVideoDummy = () => import('../../components/content/dummy/video_dummy.vue' /* webpackChunkName: "components/content-dummy-video-dummy" */).then(c => wrapFunctional(c.default || c))
export const ContentEpg = () => import('../../components/content/epg/epg.vue' /* webpackChunkName: "components/content-epg" */).then(c => wrapFunctional(c.default || c))
export const ContentEpgNav = () => import('../../components/content/epg/epgNav.vue' /* webpackChunkName: "components/content-epg-nav" */).then(c => wrapFunctional(c.default || c))
export const ContentFilmCollection = () => import('../../components/content/film_collection/collection.vue' /* webpackChunkName: "components/content-film-collection" */).then(c => wrapFunctional(c.default || c))
export const ContentModel = () => import('../../components/content/model/model.vue' /* webpackChunkName: "components/content-model" */).then(c => wrapFunctional(c.default || c))
export const ContentModelReadmoreModel = () => import('../../components/content/model/readmore_model.vue' /* webpackChunkName: "components/content-model-readmore-model" */).then(c => wrapFunctional(c.default || c))
export const ContentModelSortModel = () => import('../../components/content/model/sort_model.vue' /* webpackChunkName: "components/content-model-sort-model" */).then(c => wrapFunctional(c.default || c))
export const ContentModelVideos = () => import('../../components/content/model/videos.vue' /* webpackChunkName: "components/content-model-videos" */).then(c => wrapFunctional(c.default || c))
export const ContentProvider = () => import('../../components/content/provider/provider.vue' /* webpackChunkName: "components/content-provider" */).then(c => wrapFunctional(c.default || c))
export const ContentProviderModal = () => import('../../components/content/provider/provider_modal.vue' /* webpackChunkName: "components/content-provider-modal" */).then(c => wrapFunctional(c.default || c))
export const ContentSearch = () => import('../../components/content/search/search.vue' /* webpackChunkName: "components/content-search" */).then(c => wrapFunctional(c.default || c))
export const ContentShowEpisode = () => import('../../components/content/show/episode.vue' /* webpackChunkName: "components/content-show-episode" */).then(c => wrapFunctional(c.default || c))
export const ContentShowGalleryEpisode = () => import('../../components/content/show/gallery_episode.vue' /* webpackChunkName: "components/content-show-gallery-episode" */).then(c => wrapFunctional(c.default || c))
export const ContentShowGalleryShow = () => import('../../components/content/show/gallery_show.vue' /* webpackChunkName: "components/content-show-gallery-show" */).then(c => wrapFunctional(c.default || c))
export const ContentShowMobilePlaylist = () => import('../../components/content/show/mobilePlaylist.vue' /* webpackChunkName: "components/content-show-mobile-playlist" */).then(c => wrapFunctional(c.default || c))
export const ContentShowMore = () => import('../../components/content/show/more.vue' /* webpackChunkName: "components/content-show-more" */).then(c => wrapFunctional(c.default || c))
export const ContentShowPlaylist = () => import('../../components/content/show/playlist.vue' /* webpackChunkName: "components/content-show-playlist" */).then(c => wrapFunctional(c.default || c))
export const ContentShowReadmoreEpisode = () => import('../../components/content/show/readmore_episode.vue' /* webpackChunkName: "components/content-show-readmore-episode" */).then(c => wrapFunctional(c.default || c))
export const ContentShowReadmoreShow = () => import('../../components/content/show/readmore_show.vue' /* webpackChunkName: "components/content-show-readmore-show" */).then(c => wrapFunctional(c.default || c))
export const ContentShow = () => import('../../components/content/show/show.vue' /* webpackChunkName: "components/content-show" */).then(c => wrapFunctional(c.default || c))
export const ContentShowSortShow = () => import('../../components/content/show/sort_show.vue' /* webpackChunkName: "components/content-show-sort-show" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoFilters = () => import('../../components/content/video/filters.vue' /* webpackChunkName: "components/content-video-filters" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoGalleryVideo = () => import('../../components/content/video/gallery_video.vue' /* webpackChunkName: "components/content-video-gallery-video" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoReadmoreVideo = () => import('../../components/content/video/readmore_video.vue' /* webpackChunkName: "components/content-video-readmore-video" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoRelated = () => import('../../components/content/video/related.vue' /* webpackChunkName: "components/content-video-related" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoSeksfilmsPromo = () => import('../../components/content/video/seksfilmsPromo.vue' /* webpackChunkName: "components/content-video-seksfilms-promo" */).then(c => wrapFunctional(c.default || c))
export const ContentVideo = () => import('../../components/content/video/video.vue' /* webpackChunkName: "components/content-video" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoHighlight = () => import('../../components/content/video/videoHighlight.vue' /* webpackChunkName: "components/content-video-highlight" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoCollection = () => import('../../components/content/video/video_collection.vue' /* webpackChunkName: "components/content-video-collection" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoVideoplayer = () => import('../../components/content/video/videoplayer.vue' /* webpackChunkName: "components/content-video-videoplayer" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountFavoritesModelsFavo = () => import('../../components/content/account/favorites/models_favo.vue' /* webpackChunkName: "components/content-account-favorites-models-favo" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountFavoritesShowsFavo = () => import('../../components/content/account/favorites/shows_favo.vue' /* webpackChunkName: "components/content-account-favorites-shows-favo" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountFavoritesVideosFavo = () => import('../../components/content/account/favorites/videos_favo.vue' /* webpackChunkName: "components/content-account-favorites-videos-favo" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountProfileEmail = () => import('../../components/content/account/profile/email.vue' /* webpackChunkName: "components/content-account-profile-email" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountProfileOnbeperktStatus = () => import('../../components/content/account/profile/onbeperkt_status.vue' /* webpackChunkName: "components/content-account-profile-onbeperkt-status" */).then(c => wrapFunctional(c.default || c))
export const ContentAccountProfilePersonalDetails = () => import('../../components/content/account/profile/personal-details.vue' /* webpackChunkName: "components/content-account-profile-personal-details" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersActiveFiltersCam = () => import('../../components/content/cam/filters/active_filters_cam.vue' /* webpackChunkName: "components/content-cam-filters-active-filters-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersAllFilters = () => import('../../components/content/cam/filters/allFilters.vue' /* webpackChunkName: "components/content-cam-filters-all-filters" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersFiguur = () => import('../../components/content/cam/filters/figuur.vue' /* webpackChunkName: "components/content-cam-filters-figuur" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersListMobile = () => import('../../components/content/cam/filters/filtersListMobile.vue' /* webpackChunkName: "components/content-cam-filters-list-mobile" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersGeslacht = () => import('../../components/content/cam/filters/geslacht.vue' /* webpackChunkName: "components/content-cam-filters-geslacht" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersHaar = () => import('../../components/content/cam/filters/haar.vue' /* webpackChunkName: "components/content-cam-filters-haar" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersLeeftijd = () => import('../../components/content/cam/filters/leeftijd.vue' /* webpackChunkName: "components/content-cam-filters-leeftijd" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersMobileFilters = () => import('../../components/content/cam/filters/mobileFilters.vue' /* webpackChunkName: "components/content-cam-filters-mobile-filters" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersSearchFilterCam = () => import('../../components/content/cam/filters/searchFilterCam.vue' /* webpackChunkName: "components/content-cam-filters-search-filter-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersSearchCam = () => import('../../components/content/cam/filters/search_cam.vue' /* webpackChunkName: "components/content-cam-filters-search-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersSortCam = () => import('../../components/content/cam/filters/sort_cam.vue' /* webpackChunkName: "components/content-cam-filters-sort-cam" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersTaal = () => import('../../components/content/cam/filters/taal.vue' /* webpackChunkName: "components/content-cam-filters-taal" */).then(c => wrapFunctional(c.default || c))
export const ContentCamFiltersUiterlijk = () => import('../../components/content/cam/filters/uiterlijk.vue' /* webpackChunkName: "components/content-cam-filters-uiterlijk" */).then(c => wrapFunctional(c.default || c))
export const ContentModelAdd = () => import('../../components/content/model/favorites/model_add.vue' /* webpackChunkName: "components/content-model-add" */).then(c => wrapFunctional(c.default || c))
export const ContentModelEdit = () => import('../../components/content/model/favorites/model_edit.vue' /* webpackChunkName: "components/content-model-edit" */).then(c => wrapFunctional(c.default || c))
export const ContentModelRemove = () => import('../../components/content/model/favorites/model_remove.vue' /* webpackChunkName: "components/content-model-remove" */).then(c => wrapFunctional(c.default || c))
export const ContentShowAdd = () => import('../../components/content/show/favorites/show_add.vue' /* webpackChunkName: "components/content-show-add" */).then(c => wrapFunctional(c.default || c))
export const ContentShowEdit = () => import('../../components/content/show/favorites/show_edit.vue' /* webpackChunkName: "components/content-show-edit" */).then(c => wrapFunctional(c.default || c))
export const ContentShowRemove = () => import('../../components/content/show/favorites/show_remove.vue' /* webpackChunkName: "components/content-show-remove" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoFiltersCategories = () => import('../../components/content/video/filters/categories.vue' /* webpackChunkName: "components/content-video-filters-categories" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoFiltersModels = () => import('../../components/content/video/filters/models.vue' /* webpackChunkName: "components/content-video-filters-models" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoFiltersSort = () => import('../../components/content/video/filters/sort.vue' /* webpackChunkName: "components/content-video-filters-sort" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
