//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  data() {
    return {
      search: '',
      results: false,
    };
  },
  computed: {
    ...mapState({
      query: ({ searchStore: {query} }) => query,
    }),
  },
  methods: {
    clear () {
      var self = this;
      this.search = '';
      this.results = false;
    },
    doSearch () {
      let inputQuery = {
        type: 'input',
        name: this.search,
      };
      this.$store.commit('searchStore/setQuery', inputQuery);
      let pageQuery = {
        type: 'page',
        name: 1,
      };
      this.$store.commit('searchStore/setQuery', pageQuery);
      this.$router.push({
        path: '/zoekresultaten',
        query: { search: this.search }
      })
    },
  },
}
