export const strict = false;

export const state = () => ({
  ppmStatus: {
    action: false,
    intervalIds: [],
    count: 0,
    ppm_code: 0,
    cta: {
      disabled: false,
      text: 'Bel anoniem in'
    }
  },
  subQuery: {
    payroute: 'pin-code',
    payment_method_id: 5,
    subscription_detail_id: 8,
    subscription: 3,
    return_url: false,
    resource_id: false,
    video_id: false,
    pincode: false
  },
  countries: [
  {
    active: true,
    countrycode: 'nl',
    price: '0.90 /min.',
    price_int: '90',
    phonenumber: '0906-729 0019',
    phonenumber_int: '09067290019',
    sub_detail_id: 6,
    payroute: 'ppm/callnow',
    payment_method_id: 5,
  },
  {
    active: false,
    countrycode: 'be',
    price: '1.00 /min.',
    price_int: '100',
    phonenumber: '0906-60392',
    phonenumber_int: '090660392',
    sub_detail_id: 6,
    payroute: 'ppm/pincode',
    payment_method_id: 5,
  }
  ],
  ppmSubscription: {
    id: 0,
    published: true,
    title: 'Bellen',
    slug: 'bellen',
    price: '€0,90 p/min',
    icon: 'phone',
    type: 'choice',
    active: false,
    bundle_text: 'Te bekijken met: Bellen',
    subscription: 3,
    benefits: [
    {
      title: 'Uniek servicenummer',
      icon: 'check',
      icon_color: '#76cb1a'
    },
    {
      title: 'Zolang je aan de lijn blijft kan je de sexfilm kijken',
      icon: 'check',
      icon_color: '#76cb1a'
    },
    {
      title: 'Op Android houd je \'m op mute. Anders stopt de stream',
      icon: 'exclamation',
      icon_color: '#76cb1a'
    },
    {
      title: 'Verbreek de verbinding als je klaar bent',
      icon: 'triangle-exclamation',
      icon_color: '#f08700'
    }
    ]
  }
});

export const actions = {
  async purchase({commit, state, rootState}, path) {
    let query = JSON.parse(JSON.stringify(state.subQuery));
    var type = 'video';
    if (rootState.resourcesStore.video && path.includes('seksfilms')) {
      query.return_url = 'https://secretcircle.com/seksfilms/' + rootState.resourcesStore.video.slug;  
      query.resource_id = rootState.resourcesStore.video.id;
      query.video_id = rootState.resourcesStore.video.videos.data.film[0].id;
    }
    else if (rootState.resourcesStore.episode && path.includes('tv-shows')) {
      query.return_url = 'https://secretcircle.com/tv-shows/' + rootState.resourcesStore.showSingle.slug + '/' + rootState.resourcesStore.episode.slug;
      query.resource_id = rootState.resourcesStore.episode.id;
      query.video_id = rootState.resourcesStore.episode.videos.data.episode[0].id;
      type = 'episode';
    }
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/payment/' + state.subQuery.payroute, query)
    .then(response => {
      if (response.data.data.attributes && response.data.data.attributes.status == "ERROR") {
        var notificationObj = {
          type: 'error',
          message: 'Je pincode is onjuist.'
        };
        commit('notificationStore/setNotification', notificationObj, { root: true });
      }
      if (response && response.data && response.data.data && response.data.data.status === "AUTHORISED") {
        let cname = 'phone_stream_id';
        let cvalue = response.data.data.phone.id;
        let exdays = 1/24;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

        commit('resourcesStore/setAccess', {
          access: true,
          subscription: [{
            subscription_id: 3,
            subscription_detail_id: 8
          }],
          type: type
        }, {root: true});
      }
    }).catch((error) => {
      var notificationObj = {
        type: 'error',
        message: 'Er is iets mis gegaan met je betaling. Klik op deze melding om contact op te nemen.',
        href: '/contact'
      };
      commit('notificationStore/setNotification', notificationObj, { root: true });
    });
  },
  async call({commit, state, rootState}) {
    const activeCountry = state.countries.find(country => country.active);
    activeCountry ? activeCountry.phonenumber_int : null;
    commit('setPpmStatus', 'calling'); 
    return window.location = 'tel:' + activeCountry.phonenumber_int;
  }
}

export const mutations = {
  setCountry(state, code) {
    state.countries.forEach(function(item) {
      if (item.countrycode === code) {
        item.active = true;
      } else {
        item.active = false;
      }
    })
  },
  setPpmCode(state, ppm_code) {
    state.ppmStatus.ppm_code = ppm_code.first + ppm_code.second + ppm_code.third + ppm_code.fourth;
    state.subQuery.pincode = ppm_code.first + ppm_code.second + ppm_code.third + ppm_code.fourth;
  },
  setCta(state, todo) {
    state.ppmStatus.cta.disabled = todo;
  },
  setPpmStatus(state, action) {
    if (action === 'calling') {
      state.ppmStatus.action = action;
      state.ppmStatus.cta.disabled = true;
      state.ppmStatus.cta.text = 'Start met kijken';
    }
  }
}