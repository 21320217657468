//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions, mapMutations  } from 'vuex'
export default {
  data() {
    return {
      register: {
        newsletter: false,
        email: "",
        psw: "",
        repeat: "",
        log: "",
      },
      errors: false,
      registered: false,
      terms: false,
    }
  },
  methods: {
    async reg(event) {
      this.errors = {};
      if (!this.terms) {
        this.errors.terms = 'Je moet akkoord gaan met de algemene voorwaarden en de geldende privacy verklaring';
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Je moet akkoord gaan met de algemene voorwaarden en de geldende privacy verklaring.';
        this.$store.commit('notificationStore/setNotification', notificationObj);
      }
      if (this.register.psw != this.register.repeat) {
        this.errors.psw = 'Je wachtwoorden zijn niet gelijk aan elkaar';
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Je wachtwoorden zijn niet gelijk aan elkaar';
        this.$store.commit('notificationStore/setNotification', notificationObj);
      }
      var self = this;
      if (Object.keys(this.errors).length === 0) {
        let data = {
          email: this.register.email,
          password: this.register.psw,
          password_confirmation: this.register.repeat,
          terms: this.terms,
          newsletter: this.register.newsletter
        };
        await this.$axios.post(this.$config.apiUrl + '/api/register', data)
        .then(response => {
          if (response.data.error) {
            var notificationObj = [];
            notificationObj.type = 'error';
            notificationObj.message = response.data.error;
            self.$store.commit('notificationStore/setNotification', notificationObj);
          } else if (response.data.errors) {
            var notificationObj = [];
            notificationObj.type = 'error';
            notificationObj.message = 'We kunnen je niet registreren met deze gegevens. Probeer het opnieuw.';
            self.$store.commit('notificationStore/setNotification', notificationObj);
          } else {
            var notificationObj = [];
            notificationObj.type = 'succes';
            notificationObj.message = 'We hebben je registratie ontvangen. Verifieer je email adres om verder te gaan. Email niet ontvangen? Check je spamfolder';
            self.$store.commit('notificationStore/setNotification', notificationObj);
            self.$store.commit('dropdownStore/setDropdown', false);       
          }
        }).catch((error) => {
          if (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.data.message === 'The email has already been taken.') {
              var notificationObj = [];
              notificationObj.type = 'error';
              notificationObj.message = 'Dit e-mailadres is al geregistreerd. Login of herstel je wachtwoord.';
              self.$store.commit('notificationStore/setNotification', notificationObj);  
            } else {
              var notificationObj = [];
              notificationObj.type = 'error';
              notificationObj.message = 'We kunnen je niet registreren met deze gegevens. Probeer het opnieuw.';
              self.$store.commit('notificationStore/setNotification', notificationObj);
            }
          }
        });
      }
    },
  }
}
