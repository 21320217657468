//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breadcrumbs from "~/components/breadcrumbs";
export default {
  props: ['error'],
  components: {
    breadcrumbs
  },
}
