//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  created: function() {

  },
  data() {
    return {
      email: '',
    }
  },
  methods: {
    ...mapActions({
      setNotification: 'contentStore/catchNotification'
    }),
    reset: function(event) {
     var self = this;
     let data = {
      email: this.email,
    };
    this.$axios.post(this.$config.apiUrl + '/api/user-forgot-password', data).then(function (response) {
      var notificationObj = [];
      notificationObj.type = 'succes';
      notificationObj.message = 'Als er een account is met jouw emailadres hebben we je een mail gestuurd met een link om je wachtwoord te resetten. Mail niet ontvangen? Check je SPAM-box.';
      self.$store.commit('notificationStore/setNotification', notificationObj);
    })
  }
}
}
