//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import login from '~/components/user/login'
import register from '~/components/user/register'
import forgot from '~/components/user/forgot'
import userChecks from '~/mixins/userChecks';
export default {
  mixins: [userChecks],
  components: {
    login,
    register,
    forgot
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.loggedIn;
    }
  },
  data() {
    return {
      tabs: {
        login: true,
        register: false,
        forget: false
      },
      auth: this.$root.$data.auth
    };
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      if (this.$route.name.includes('account')) {
        this.$router.push({
          path: '/'
        })
      }
      this.$emit('closeAll');
    },
    toggleTab: function(tab) {
      var close = 1;
      if (this.tabs[tab]) {
        close = 0;
      }
      this.closeTabs();
      if (close) {
        this.tabs[tab] = !this.tabs[tab];
        this.$emit('logintab', tab)
      }
    },
    closeTabs: function(all) {
      this.tabs = {
        login: false,
        register: false,
        forget: false,
      }
      if (all) {
        this.$emit('closeAll');
      }
    },
  },
}
