//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navigation from '~/components/Navigation.vue'
import searchbar from './searchbar'
import info from './info'
import login from './userLogin'

export default { 
    components: {
        Navigation,
        searchbar,
        info,
        login
    },
    props: ['scrollInnit'],
    watch: {
        '$route.path': function() {
            this.dropdown = false;
            if (this.navActive) {
                this.navActive = false;
            }

        }
    },
    data() {
        return {
            navActive: false,
            activeLoginTab: 'login',
            nav: {
                home: false,
                sexfilms: false,
                modellen: false,
                webcams: false,
                categorieen: false,
            },
            dropdown: false,
            scroll: false,
            lastPos: 0
        }
    },
    methods:{
        toggleDropdown(item){
            if (this.dropdown == item) {
                this.dropdown = false;
            } else {
                this.dropdown = item;
            }
        },
        logintab: function(tab) {
            this.activeLoginTab = tab;
        },
    },
}
