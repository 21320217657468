//
//
//
//
//
//
//
//
//

import cookie from '~/components/cookie'
import Header from '~/components/header/Header'
import Footer from '~/components/Footer'
import Navigation from '~/components/Navigation'

// This layout is being used to be able to show a page without the Cookie pop-up, even if the user has not yet accepted cookies.
// The pages that are using this layout are:
// /info/privacy

export default {
  components: {
    cookie,
    Header,
    Footer,
    Navigation
  },
  watch: {

  },
  data() {      
    return {
      lastPos: 0,
    };
  },
  methods: {
   handleScroll(event) {
    var position = window.scrollY;
    if (position < 0) {
      position = 0;
    }
    if (this.lastPos <= 0) {
      this.lastPos = position;
    }

    if (position > (this.lastPos + 100)) {
      this.lastPos = position;
      this.$root.scroll = true;
    } else if (position < (this.lastPos - 100)) {
      this.lastPos = position;
      this.$root.scroll = false;
    }
    if (position > 20) {
      this.$root.notontop = true;
    } else {
      this.$root.notontop = false;
    }
    
  },
},
created: function() {
  window.addEventListener('scroll', this.handleScroll);
},
destroyed() {
  window.removeEventListener('scroll', this.handleScroll);
},
}
