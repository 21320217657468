export const queries = {
	videos: {
		content: 'videos',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 21
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'thumb',
						3: 'cover_thumb'
					},
				}],
			},
			tags: {}
		}],
	},
	video: {
		content: 'video',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'category',
						1: 'film_collection',
						2: 'tags'
					},
					status: 'published'
				}],
				include: [{
					resources: {
						filters: [{
							types: {
								0: 'video'
							},
							status: 'published'
						}],
						include: [{
							images: {
								filters: [{
									types: {
										0: 'cover',
										1: 'home_cover',
										2: 'thumb',
										3: 'cover_thumb'
									},
								}],
							},	
						}]
					},
					images: {
						filters: [{
							types: {
								0: 'cover',
								1: 'home_cover',
								2: 'thumb',
								3: 'cover_thumb'
							},
						}],
					},
				}],
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'thumb',
						3: 'cover_thumb'
					},
				}],
			},
			related: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				include: [{
					images: {
						filters: [{
							types: {
								0: 'cover',
								1: 'thumb'
							}
						}],
					}
				}]
			},
			seo: {},
			images: {},
			clips: {},
			videos: {},
			tags: {}
		}],
	},
	models: {
		content: 'models',
		types: {
			0: 'model',
		},
		sort: {
			published_at: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: "video"
					},
					status: "published"
				}],
				include: [{
					images: {},
					seo: {},
					clips: {},
					videos: {},
					extra: {}
				}]
			},
			images: {
				filters: [{
					types: {
						0: "model_thumb",
						1: "square"
					}
				}]
			}
		}],
	},
	model: {
		content: 'modelSingle',
		types: {
			0: 'model',
		},
		filters: {
			status: 'published'
		},
		include: [{
			resources: {
				filters: [{
					status: 'published'
				}],
				include: [{
					images: {
						filters: [{
							types: {
								0: 'cover'
							}
						}]
					},
					seo: {},
					videos: {},
					clips: {},
					extra: {}
				}]
			},
			seo: {},
			images: {},
			clips: {},
			videos: {},
			related: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				include: [{
					images: {
						filters: [{
							types: {
								0: 'cover'
							}
						}],
					}
				}]
			},
			extra: {}
		}],
	},
	videoOfTheWeek: {
		content: 'videoOfTheWeek',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['video_of_the_week']
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'home_cover'
					},
				}],
			},
			tags: {},
			clips: {},
			videos: {}
		}],
	},
	videoForYou: {
		content: 'videoForYou',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['home_promo']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover_thumb',
						1: 'home_cover',
						2: 'thumb'
					},
				}],
			},
			videos: {},
			tags: {},
			clips: {}
		}],
	},
	seksfilmsPromo: {
		content: 'seksfilmsPromo',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['seksfilms_promo']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'home_cover',
						2: 'cover'
					},
				}],
			},
			videos: {},
			tags: {},
			clips: {}
		}],
	},
	recommendedVideos: {
		content: 'recommendedVideos',
		types: {
			0: 'video',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover'
					},
				}],
			},
			tags: {}
		}],
	},
	mostRecent: {
		content: 'mostRecent',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover'
					},
				}],
			},
			tags: {}
		}],
	},
	planned: {
		content: 'planned',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			planned: 1
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'cover_thumb'
					},
				}],
			},
			videos: {},
			tags: {}
		}],
	},
	tempFree: {
		content: 'tempFree',
		types: {
			0: 'video',
		},
		sort: {
			published_at: 'desc'
		},
		filters: {
			status: 'published',
			labels: ['temporary_free']
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover',
						2: 'cover_thumb'
					},
				}],
			},
			videos: {},
			tags: {},
			labels: {}
		}],
	},
	topVideos: {
		content: 'topVideos',
		types: {
			0: 'video',
		},
		sort: {
			views: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'tags',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'cover',
						1: 'home_cover'
					},
				}],
			},
			tags: {}
		}],
	},
	shows: {
		content: 'shows',
		types: {
			0: 'show',
		},
		sort: {
			published_at: "desc"
		},
		filters: {
			status: "published"
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: "episode"
					},
					status: "published"
				}],
				include: [{
					images: {},
					seo: {},
					clips: {},
					videos: {},
					extra: {}
				}]
			},
			images: {
				filters: [{
					types: {
						0: "thumb"
					}
				}]
			},
			clips: {}
		}],
	},
	show: {
		content: 'showSingle',
		types: {
			0: 'show',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'episode',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
				include: [{
					images: {},
					seo: {},
					clips: {},
					videos: {},
					extra: {}
				}]
			},
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			tags: {},
			videos: {}
		}],
	},
	episode: {
		content: 'episode',
		types: {
			0: 'episode',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'show',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
				include: [{
					resources: {
						filters: [{
							types: {
								0: 'episode',
							},
							status: 'published'
						}],
						include: [{
							videos: {},
							extra: {}
						}]
					},
				}]
			},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						1: 'album'
					},
				}],
			},
			tags: {},
			videos: {},
			extra: {}
		}],
	},
	tags: {
		content: 'tags',
		types: {
			0: 'tags',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			tags: {}
		}],
	},
	category: {
		content: 'category',
		types: {
			0: 'category',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
			images: {
				filters: [{
					types: {
						0: 'thumb'
					},
				}],
			},
			tags: {}
		}],
	},
	tags: {
		content: 'tags',
		types: {
			0: 'tags',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
	},
	tag: {
		content: 'tag',
		types: {
			0: 'tags',
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 10
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
				images: {
					filters: [{
						types: {
							0: 'thumb'
						},
					}],
				},
			},
		}],
	},
	collections: {
		content: 'film_collections',
		types: {
			0: 'film_collection',
		},
		sort: {
			shuffle: 'desc'
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 3
		},
		include: [{
			resources: {
				filters: [{
					types: {
						0: 'video',
					},
					status: 'published'
				}],
			},
			images: {},
			tags: {}
		}],
	},
	search : {
		content: 'search',
		types: {
			0: 'video',
          // 1: 'model',
          // 2: 'category'
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
		},
		search: {
			all: ''
		},
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			seo: {},
			images: {
				filters: [{
					types: {
						0: 'thumb',
						2: 'square',
						3: 'cover',
						4: 'cover_thumb'
					}
				}],
			},
			clips: {},
			extra: {}
		}]
	},
	providers : {
		content: 'providers',
		types: {
			0: 'provider',
          // 1: 'model',
          // 2: 'category'
		},
		sort: {
			recommended_at: 'desc'
		},
		filters: {
			status: 'published',
		},
		search: '',
		pagination: {
			page: 1,
			per_page: 12
		},
		include: [{
			seo: {},
			images: {
				filters: [{
					types: {
						0: 'square',
					}
				}],
			},
			clips: {},
			extra: {}
		}]
	},
	preroll: {
		content: 'preroll',
		types: {
			0: 'preroll',
		},
		sort: {
			random: 'desc',
		},
		filters: {
			status: 'published'
		},
		pagination: {
			page: 1,
			per_page: 1
		},
		include: [{
			videos: {}
		}]
	},
};