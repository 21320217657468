  export const strict = false;

  export const state = () => ({
    notification: false
  })

  export const actions = {   
    catchNotification(context, notificationObj ) {
      context.commit('setNotification', notificationObj);
    }
  }

  export const getters = {
    getNotificationState(state) {
      return state.notification;
    }
  }

  export const mutations = {
    setNotification(state, notification) {
      state.notification = notification;
    }
  }