import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';

export const strict = false;

export const state = () => ({
  popunder: false
})

export const actions = {
  async getPopunder({commit}, query) {
    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);   
    await this.$axios.get(process.env.API_URL + '/others?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&type=popunder' + '&frontend_id=5')
    .then(response => { 
      commit('setPopunder', response.data);        
    }).catch((error) => {
      console.log(error);
    });
  },
}

export const getters = {
  popunder(state) {
    return state.popunder;
  }
}

export const mutations = {
  setPopunder(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var popunders = deserialize(dataObj);

    if(!popunders.length) return;

    var randomVersion = Math.floor(Math.random() * popunders.length);

    var popunder = popunders[randomVersion];

    var randomImage = Math.floor(Math.random() * popunder.images.length);
    popunder.image = popunder.images[randomImage];

    state.popunder = popunder;
  },
}