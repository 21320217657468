  /*
  ** JS functions to we use on multiple components
  */
  import Vue from 'vue'
  Vue.mixin({
    methods: {
      scrollBack(id) {
        var elmnt = document.getElementById('hscroll_' + id);
        elmnt.scrollLeft -= elmnt.offsetWidth - 100;
        if (!this.$root.ieEdge) {
          if (elmnt.scrollLeft <= elmnt.offsetWidth) {
            this.backEnd = true;
            this.forwardEnd = false;
          } else {
            this.backEnd = false;
            this.forwardEnd = false;
          }
        } else {
          this.backEnd = false;
          this.forwardEnd = false;
        }
      },
      scrollForward(id) {
        var elmnt = document.getElementById('hscroll_' + id);
        elmnt.scrollLeft += elmnt.offsetWidth - 120;
        if ((elmnt.scrollWidth - elmnt.offsetWidth) ==  elmnt.scrollLeft) {
          elmnt.scrollLeft = 0;
          this.backEnd = true;
        } else {
          this.backEnd = false;
        }
      },
      goto(to) {
        var options = {
         duration: 750,
         easing: "ease",
         offset: -50
       } 
       var self = this;
       setTimeout(function() {
         self.$scrollTo('#' + to, 500, options)
       }, 500);
     },
   }
 })