/*
** JS functions to let user scroll through horizontal content
*/
import Vue from 'vue'
Vue.mixin({
  methods: {
    getBanner(zone, size) {
     let bannerQuery = { 
      zone: zone,
      size: size
    }
    this.$store.dispatch('bannerStore/getBanner', bannerQuery);
  },
}
})