
export const state = () => ({
    queries: {
      banner: {
        filters: {
            location: ['Collection']
        },      
        pagination: {
          page: 1,
          per_page: 1
      },
      include: [{
        images: {}
    }]
  }
},
banner: {},
loading: false
})
export const actions = {
    async getBanner({commit, state}, location) {
        if (location) {
            commit('UPDATE_LOCATION', location);
        }
        commit('setLoading', true); 
        await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/banners/nl?query=' + objectToJsonUrl(state.queries.banner))
        .then(response => {
            commit('setBanner', response.data);
            commit('setLoading', false); 
        }).catch((error) => {
            commit('setLoading', 'error'); 
        });
    }
}

export const mutations = {
    setBanner(state, data) {              
        state.banner = data.data[0];
    },
    setLoading(state, todo){
      state.loading = todo;
  },
  UPDATE_LOCATION(state, location) {
    state.queries.banner.location = [location];
},
}


const jsonToUrlValue = (value) => {
    if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
      return String(value);
  } else if (typeof value === 'string') {
        // Quote the string if necessary
      if (['true', 'false', 'null'].includes(value) || !isNaN(Number(value))) {
        return `'${value}'`;
    }
    return value.replace(/ /g, '+');
} else if (Array.isArray(value)) {
  return `(${value.map(jsonToUrlValue).join(',')})`;
} else if (typeof value === 'object') {
  const entries = Object.entries(value).map(([key, val]) => `${key}:${jsonToUrlValue(val)}`);
  return `(${entries.join(',')})`;
}
    // Fallback for undefined or functions
return '';
};

const objectToJsonUrl = (obj) => {
    return encodeURIComponent(jsonToUrlValue(obj));
};