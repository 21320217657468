import Vue from 'vue';
export const strict = false;

export const state = () => ({
  loadState: {
    allVideos: '',
    topVideos: '',
    newVideos: '',
    video: '',
    related: '',
    votw: '',
    vfy: '',
    sfp: '',
    highlight: '',
    categories: '',
    models: '',
    model: '',
    shows: '',
    show: '',
    cams: '',
    epg: '',
    blogs: '',
    search: '',
    favorites: '',
    account: '',
    recent: '',
    plannedVideos: '',
    freeVideos: '',
  },
  pagination: {
    videos: '',
    models: '',
    shows: '',
    cams: '',
    search: ''
  },
})

export const getters = {
  getLoadState(state) {
    return state.loadState;
  },
  getLoadStateProperty: (state) => (property) => {
    console.log(property)
    return state.loadState[property];
  },
  getPagination(state) {
    return state.pagination;
  }
}

export const mutations = {
  setLoadState(state, loadingObj) {
    // reactivity fix if property doesn't exist yet
    Vue.set(state.loadState, loadingObj.content, loadingObj.type);
  },
  setPagination(state, paginationObj) {
    Vue.set(state.pagination, paginationObj.content, paginationObj.pagination);
  }
}